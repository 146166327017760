// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          description: js.description,
          fullAddress: js.fullAddress,
          streetAddress1: js.streetAddress1,
          city: js.city,
          state: js.state,
          zip: js.zip,
          country: js.country,
          continent: js.continent,
          longitude: js.longitude,
          latitude: js.latitude,
          grossBuildingSize: js.grossBuildingSize,
          grossColocationSpace: js.grossColocationSpace,
          totalPowerMw: js.totalPowerMw,
          powerDensity: js.powerDensity,
          carrierNeutral: js.carrierNeutral,
          nearestAirport: js.nearestAirport,
          nearestAirportOverride: js.nearestAirportOverride,
          updatedAt: $$Date.Naive.fromString(js.updatedAt),
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              streetAddress1: field.required("streetAddress1", Json_Decode$JsonCombinators.string),
              city: field.required("city", Json_Decode$JsonCombinators.string),
              state: field.required("state", Json_Decode$JsonCombinators.string),
              zip: field.required("zip", Json_Decode$JsonCombinators.string),
              country: field.required("country", Json_Decode$JsonCombinators.string),
              continent: field.required("continent", Json_Decode$JsonCombinators.string),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              grossBuildingSize: field.required("grossBuildingSize", Json_Decode$JsonCombinators.string),
              grossColocationSpace: field.required("grossColocationSpace", Json_Decode$JsonCombinators.string),
              totalPowerMw: field.required("totalPowerMw", Json_Decode$JsonCombinators.string),
              powerDensity: field.required("powerDensity", Json_Decode$JsonCombinators.string),
              carrierNeutral: field.required("carrierNeutral", Json_Decode$JsonCombinators.bool),
              nearestAirport: field.required("nearestAirport", Json_Decode$JsonCombinators.string),
              nearestAirportOverride: field.required("nearestAirportOverride", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
