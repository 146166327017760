// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Qs from "qs";
import * as $$Date from "../libs/Date.res.js";
import * as $$Image from "./Image.res.js";
import * as Video from "./Video.res.js";
import * as Provider from "./Provider.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Cloudinary from "../libs/Cloudinary.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LocationDraft from "./LocationDraft.res.js";
import * as LocationFeature from "./LocationFeature.res.js";
import * as LocationSetting from "./LocationSetting.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as DataCenterCertification from "./DataCenterCertification.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          streetAddress1: js.streetAddress1,
          streetAddress2: js.streetAddress2,
          longitude: js.longitude,
          latitude: js.latitude,
          country: js.country,
          state: js.state,
          city: js.city,
          zip: js.zip,
          fullAddress: js.fullAddress,
          grossBuildingSize: js.grossBuildingSize,
          grossColocationSpace: js.grossColocationSpace,
          totalPowerMw: js.totalPowerMw,
          powerDensity: js.powerDensity,
          nearestAirport: js.nearestAirport,
          url: js.url,
          editUrl: js.editUrl,
          profileSlugUrl: js.profileSlugUrl,
          imageUrl: js.imageUrl,
          certifications: js.certifications,
          createdAt: Belt_Option.map(Caml_option.nullable_to_opt(js.createdAt), $$Date.Utc.fromString),
          updatedAt: Belt_Option.map(Caml_option.nullable_to_opt(js.updatedAt), $$Date.Utc.fromString),
          summary: js.summary
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              streetAddress1: field.required("streetAddress1", Json_Decode$JsonCombinators.string),
              streetAddress2: field.required("streetAddress2", Json_Decode$JsonCombinators.string),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              country: field.required("country", Json_Decode$JsonCombinators.string),
              state: field.required("state", Json_Decode$JsonCombinators.string),
              city: field.required("city", Json_Decode$JsonCombinators.string),
              zip: field.required("zip", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              grossBuildingSize: field.required("grossBuildingSize", Json_Decode$JsonCombinators.string),
              grossColocationSpace: field.required("grossColocationSpace", Json_Decode$JsonCombinators.string),
              totalPowerMw: field.required("totalPowerMw", Json_Decode$JsonCombinators.string),
              powerDensity: field.required("powerDensity", Json_Decode$JsonCombinators.string),
              nearestAirport: field.required("nearestAirport", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              editUrl: field.required("editUrl", Json_Decode$JsonCombinators.string),
              profileSlugUrl: field.required("profileSlugUrl", Json_Decode$JsonCombinators.string),
              imageUrl: field.required("imageUrl", Json_Decode$JsonCombinators.string),
              certifications: field.required("certifications", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.option($$Date.Utc.decoder)),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.option($$Date.Utc.decoder)),
              summary: field.required("summary", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function facilityFromJs(js) {
  return {
          name: js.name,
          slug: js.slug,
          live: js.live
        };
}

function salesManagerFromJs(js) {
  return {
          name: js.name,
          title: js.title,
          image: js.image
        };
}

function fromJs$1(js) {
  return {
          id: js.id,
          name: js.name,
          summary: js.summary,
          description: js.description,
          fullAddress: js.fullAddress,
          phone: js.phone,
          grossBuildingSize: js.grossBuildingSize,
          grossColocationSpace: js.grossColocationSpace,
          totalPowerMw: js.totalPowerMw,
          powerDensity: js.powerDensity,
          totalProducts: js.totalProducts,
          nearestAirport: js.nearestAirport,
          url: js.url,
          editUrl: js.editUrl,
          features: Belt_Array.map(js.features, LocationFeature.fromJs),
          image: js.image,
          facilities: Belt_Array.map(js.facilities, facilityFromJs),
          certifications: Belt_Array.map(js.certifications, DataCenterCertification.Show.fromJs),
          latitude: js.latitude,
          longitude: js.longitude,
          sponsoredGlobal: js.sponsoredGlobal,
          sponsoredCountry: js.sponsoredCountry,
          sponsoredState: js.sponsoredState,
          sponsoredCity: js.sponsoredCity,
          salesManager: Belt_Option.map(Caml_option.nullable_to_opt(js.salesManager), salesManagerFromJs)
        };
}

var facilityDecoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              live: field.required("active", Json_Decode$JsonCombinators.bool)
            };
    });

function facilityFromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, facilityDecoder));
}

var salesManagerDecoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              name: field.required("name", Json_Decode$JsonCombinators.string),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.string)
            };
    });

function salesManagerFromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, salesManagerDecoder));
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              summary: field.required("summary", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              phone: field.required("phone", Json_Decode$JsonCombinators.string),
              grossBuildingSize: field.required("grossBuildingSize", Json_Decode$JsonCombinators.string),
              grossColocationSpace: field.required("grossColocationSpace", Json_Decode$JsonCombinators.string),
              totalPowerMw: field.required("totalPowerMw", Json_Decode$JsonCombinators.string),
              powerDensity: field.required("powerDensity", Json_Decode$JsonCombinators.string),
              totalProducts: field.required("totalProducts", Json_Decode$JsonCombinators.$$int),
              nearestAirport: field.required("nearestAirport", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              editUrl: field.required("editUrl", Json_Decode$JsonCombinators.string),
              features: field.required("features", Json_Decode$JsonCombinators.array(LocationFeature.decoder)),
              image: field.required("image", Json_Decode$JsonCombinators.string),
              facilities: field.required("facilities", Json_Decode$JsonCombinators.array(facilityDecoder)),
              certifications: field.required("certifications", Json_Decode$JsonCombinators.array(DataCenterCertification.Show.decoder)),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float),
              sponsoredGlobal: field.required("sponsoredGlobal", Json_Decode$JsonCombinators.bool),
              sponsoredCountry: field.required("sponsoredCountry", Json_Decode$JsonCombinators.bool),
              sponsoredState: field.required("sponsoredState", Json_Decode$JsonCombinators.bool),
              sponsoredCity: field.required("sponsoredCity", Json_Decode$JsonCombinators.bool),
              salesManager: field.required("salesManager", Json_Decode$JsonCombinators.option(salesManagerDecoder))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Show = {
  facilityFromJs: facilityFromJs,
  salesManagerFromJs: salesManagerFromJs,
  fromJs: fromJs$1,
  facilityDecoder: facilityDecoder,
  facilityFromJson: facilityFromJson,
  salesManagerDecoder: salesManagerDecoder,
  salesManagerFromJson: salesManagerFromJson,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$2(js) {
  return {
          id: js.id,
          name: js.name,
          city: js.city,
          state: js.state,
          country: js.country,
          active: js.active,
          isApproved: js.isApproved,
          url: js.url,
          providerId: js.providerId,
          providerName: js.providerName,
          providerSlug: js.providerSlug,
          lastApprovedOn: $$Date.Naive.fromString(js.lastApprovedOn),
          createdAt: $$Date.Naive.fromString(js.createdAt)
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              city: field.required("city", Json_Decode$JsonCombinators.string),
              state: field.required("state", Json_Decode$JsonCombinators.string),
              country: field.required("country", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              isApproved: field.required("isApproved", Json_Decode$JsonCombinators.bool),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerSlug: field.required("providerSlug", Json_Decode$JsonCombinators.string),
              lastApprovedOn: field.required("lastApprovedOn", $$Date.Naive.decoder),
              createdAt: field.required("createdAt", $$Date.Naive.decoder)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Dashboard = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2
};

function fromJs$3(js) {
  return {
          id: js.id,
          title: js.title,
          abbreviation: js.abbreviation,
          summary: js.summary,
          certificationLocationId: js.certificationLocationId,
          live: js.live,
          requested: js.requested,
          approved: js.approved
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              abbreviation: field.required("abbreviation", Json_Decode$JsonCombinators.string),
              summary: field.required("summary", Json_Decode$JsonCombinators.string),
              certificationLocationId: field.required("certificationLocationId", ID.decoder),
              live: field.required("live", Json_Decode$JsonCombinators.bool),
              requested: field.required("requested", Json_Decode$JsonCombinators.bool),
              approved: field.required("approved", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Certification = {
  fromJs: fromJs$3,
  decoder: decoder$3,
  fromJson: fromJson$3
};

function fromJs$4(js) {
  return {
          id: js.id,
          title: js.title,
          facilityLocationId: js.facilityLocationId,
          live: js.live,
          requested: js.requested,
          approved: js.approved
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              facilityLocationId: field.required("facilityLocationId", ID.decoder),
              live: field.required("live", Json_Decode$JsonCombinators.bool),
              requested: field.required("requested", Json_Decode$JsonCombinators.bool),
              approved: field.required("approved", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var Facility = {
  fromJs: fromJs$4,
  decoder: decoder$4,
  fromJson: fromJson$4
};

function fromJs$5(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          slug: js.slug,
          description: js.description,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          fullAddress: js.fullAddress,
          streetAddress1: js.streetAddress1,
          streetAddress2: js.streetAddress2,
          city: js.city,
          state: js.state,
          zip: js.zip,
          country: js.country,
          continent: js.continent,
          longitude: js.longitude,
          latitude: js.latitude,
          grossBuildingSize: js.grossBuildingSize,
          grossColocationSpace: js.grossColocationSpace,
          totalPowerMw: js.totalPowerMw,
          powerDensity: js.powerDensity,
          carrierNeutral: js.carrierNeutral,
          nearestAirport: js.nearestAirport,
          nearestAirportOverride: js.nearestAirportOverride,
          overrideCity: js.overrideCity,
          hidden: js.hidden,
          lastApprovedOn: $$Date.Naive.fromString(js.lastApprovedOn),
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt),
          currentDraft: LocationDraft.fromJs(js.currentDraft),
          isApproved: js.isApproved,
          features: Belt_Array.map(js.features, LocationFeature.Edit.fromJs),
          certifications: Belt_Array.map(js.certifications, fromJs$3),
          facilities: Belt_Array.map(js.facilities, fromJs$4),
          settings: LocationSetting.fromJs(js.settings),
          videos: Belt_Array.map(js.videos, Video.fromJs),
          images: Belt_Array.map(js.images, $$Image.fromJs)
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              streetAddress1: field.required("streetAddress1", Json_Decode$JsonCombinators.string),
              streetAddress2: field.required("streetAddress2", Json_Decode$JsonCombinators.string),
              city: field.required("city", Json_Decode$JsonCombinators.string),
              state: field.required("state", Json_Decode$JsonCombinators.string),
              zip: field.required("zip", Json_Decode$JsonCombinators.string),
              country: field.required("country", Json_Decode$JsonCombinators.string),
              continent: field.required("continent", Json_Decode$JsonCombinators.string),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              grossBuildingSize: field.required("grossBuildingSize", Json_Decode$JsonCombinators.string),
              grossColocationSpace: field.required("grossColocationSpace", Json_Decode$JsonCombinators.string),
              totalPowerMw: field.required("totalPowerMw", Json_Decode$JsonCombinators.string),
              powerDensity: field.required("powerDensity", Json_Decode$JsonCombinators.string),
              carrierNeutral: field.required("carrierNeutral", Json_Decode$JsonCombinators.bool),
              nearestAirport: field.required("nearestAirport", Json_Decode$JsonCombinators.string),
              nearestAirportOverride: field.required("nearestAirportOverride", Json_Decode$JsonCombinators.string),
              overrideCity: field.required("overrideCity", Json_Decode$JsonCombinators.string),
              hidden: field.required("hidden", Json_Decode$JsonCombinators.bool),
              lastApprovedOn: field.required("lastApprovedOn", $$Date.Naive.decoder),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              currentDraft: field.required("currentDraft", LocationDraft.decoder),
              isApproved: field.required("isApproved", Json_Decode$JsonCombinators.bool),
              features: field.required("features", Json_Decode$JsonCombinators.array(LocationFeature.Edit.decoder)),
              certifications: field.required("certifications", Json_Decode$JsonCombinators.array(decoder$3)),
              facilities: field.required("facilities", Json_Decode$JsonCombinators.array(decoder$4)),
              settings: field.required("settings", LocationSetting.decoder),
              videos: field.required("videos", Json_Decode$JsonCombinators.array(Video.decoder)),
              images: field.required("images", Json_Decode$JsonCombinators.array($$Image.decoder))
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

function viewToString(x) {
  if (x === "Live") {
    return "Live View";
  } else {
    return "Draft View";
  }
}

var Live = {
  viewToString: viewToString
};

var Edit = {
  fromJs: fromJs$5,
  decoder: decoder$5,
  fromJson: fromJson$5,
  Live: Live
};

function toJson(active) {
  if (active === "Active") {
    return true;
  } else {
    return false;
  }
}

function toString(value) {
  if (value === "Active") {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromJs$6(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromBool(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

var Active = {
  toJson: toJson,
  toString: toString,
  fromJs: fromJs$6,
  fromBool: fromBool
};

function fromJs$7(js) {
  return {
          id: js.id,
          name: js.name,
          description: js.description,
          fullAddress: js.fullAddress,
          grossBuildingSize: js.grossBuildingSize,
          grossColocationSpace: js.grossColocationSpace,
          totalPowerMw: js.totalPowerMw,
          powerDensity: js.powerDensity,
          nearestAirport: js.nearestAirport,
          longitude: js.longitude,
          latitude: js.latitude,
          createdAt: Belt_Option.map(Caml_option.nullable_to_opt(js.createdAt), $$Date.Utc.fromString),
          updatedAt: Belt_Option.map(Caml_option.nullable_to_opt(js.updatedAt), $$Date.Utc.fromString),
          provider: Provider.fromJs(js.provider),
          images: Belt_Array.map(js.images, $$Image.fromJs),
          certifications: js.certifications,
          imageUrl: js.imageUrl,
          productsCount: js.productsCount,
          minPrice: Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(js.minPrice), Belt_Float.fromString)),
          maxPrice: Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(js.maxPrice), Belt_Float.fromString)),
          url: js.url,
          summary: js.summary
        };
}

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              grossBuildingSize: field.required("grossBuildingSize", Json_Decode$JsonCombinators.string),
              grossColocationSpace: field.required("grossColocationSpace", Json_Decode$JsonCombinators.string),
              totalPowerMw: field.required("totalPowerMw", Json_Decode$JsonCombinators.string),
              powerDensity: field.required("powerDensity", Json_Decode$JsonCombinators.string),
              nearestAirport: field.required("nearestAirport", Json_Decode$JsonCombinators.string),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.option($$Date.Utc.decoder)),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.option($$Date.Utc.decoder)),
              provider: field.required("provider", Provider.decoder),
              images: field.required("images", Json_Decode$JsonCombinators.array($$Image.decoder)),
              certifications: field.required("certifications", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              imageUrl: field.required("imageUrl", Json_Decode$JsonCombinators.string),
              productsCount: field.required("productsCount", Json_Decode$JsonCombinators.$$int),
              minPrice: field.required("minPrice", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$float)),
              maxPrice: field.required("maxPrice", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$float)),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              summary: field.required("summary", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$6(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$6));
}

var Detail = {
  fromJs: fromJs$7,
  decoder: decoder$6,
  fromJson: fromJson$6
};

function fromJs$8(js) {
  return {
          id: js.id,
          name: js.name,
          latitude: js.latitude,
          longitude: js.longitude,
          fullAddress: js.fullAddress,
          url: js.url,
          providerId: js.providerId,
          providerName: js.providerName,
          providerLogo: js.providerLogo,
          productsCount: js.productsCount,
          minPrice: Belt_Option.getExn(Belt_Float.fromString(js.minPrice)),
          maxPrice: Belt_Option.getExn(Belt_Float.fromString(js.maxPrice)),
          imageUrl: js.imageUrl,
          sponsoredGlobal: js.sponsoredGlobal,
          sponsoredCountry: js.sponsoredCountry,
          sponsoredState: js.sponsoredState,
          sponsoredCity: js.sponsoredCity
        };
}

var decoder$7 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              productsCount: field.required("productsCount", Json_Decode$JsonCombinators.$$int),
              minPrice: field.required("minPrice", Json_Decode$JsonCombinators.$$float),
              maxPrice: field.required("maxPrice", Json_Decode$JsonCombinators.$$float),
              imageUrl: field.required("imageUrl", Json_Decode$JsonCombinators.string),
              sponsoredGlobal: field.required("sponsoredGlobal", Json_Decode$JsonCombinators.bool),
              sponsoredCountry: field.required("sponsoredCountry", Json_Decode$JsonCombinators.bool),
              sponsoredState: field.required("sponsoredState", Json_Decode$JsonCombinators.bool),
              sponsoredCity: field.required("sponsoredCity", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$7(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$7));
}

var ForMap = {
  fromJs: fromJs$8,
  decoder: decoder$7,
  fromJson: fromJson$7
};

function fromJs$9(js) {
  return {
          id: js.id,
          name: js.name,
          fullAddress: js.fullAddress,
          sponsoredGlobal: js.sponsoredGlobal,
          sponsoredCountry: js.sponsoredCountry,
          sponsoredState: js.sponsoredState,
          sponsoredCity: js.sponsoredCity,
          url: js.url,
          providerId: js.providerId,
          providerName: js.providerName,
          providerLogo: js.providerLogo,
          providerAgreement: js.providerAgreement,
          productsCount: js.productsCount,
          minPrice: Belt_Option.getExn(Belt_Float.fromString(js.minPrice)),
          maxPrice: Belt_Option.getExn(Belt_Float.fromString(js.maxPrice)),
          imageUrl: js.imageUrl
        };
}

var decoder$8 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              sponsoredGlobal: field.required("sponsoredGlobal", Json_Decode$JsonCombinators.bool),
              sponsoredCountry: field.required("sponsoredCountry", Json_Decode$JsonCombinators.bool),
              sponsoredState: field.required("sponsoredState", Json_Decode$JsonCombinators.bool),
              sponsoredCity: field.required("sponsoredCity", Json_Decode$JsonCombinators.bool),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              providerAgreement: field.required("providerAgreement", Json_Decode$JsonCombinators.bool),
              productsCount: field.required("productsCount", Json_Decode$JsonCombinators.$$int),
              minPrice: field.required("minPrice", Json_Decode$JsonCombinators.$$float),
              maxPrice: field.required("maxPrice", Json_Decode$JsonCombinators.$$float),
              imageUrl: field.required("imageUrl", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$8(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$8));
}

var ForTile = {
  fromJs: fromJs$9,
  decoder: decoder$8,
  fromJson: fromJson$8
};

function fromJs$10(js) {
  return {
          locations: Belt_Array.map(js.locations, fromJs$9),
          mapLocations: Belt_Array.map(js.mapLocations, fromJs$8),
          providers: Belt_Array.map(js.providers, Provider.ListItem.fromJs),
          currentPage: js.currentPage,
          totalPages: js.totalPages,
          totalLocations: js.totalLocations,
          offset: js.offset
        };
}

var decoder$9 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              locations: field.required("locations", Json_Decode$JsonCombinators.array(decoder$8)),
              mapLocations: field.required("mapLocations", Json_Decode$JsonCombinators.array(decoder$7)),
              providers: field.required("providers", Json_Decode$JsonCombinators.array(Provider.ListItem.decoder)),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$9(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$9));
}

var Index = {
  fromJs: fromJs$10,
  decoder: decoder$9,
  fromJson: fromJson$9
};

function fromJs$11(js) {
  return {
          locations: Belt_Array.map(js.locations, fromJs$9),
          totalLocations: js.totalLocations,
          currentPage: js.currentPage,
          totalPages: js.totalPages,
          offset: js.offset
        };
}

var decoder$10 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              locations: field.required("locations", Json_Decode$JsonCombinators.array(decoder$8)),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$10(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$10));
}

var RelatedLocations = {
  fromJs: fromJs$11,
  decoder: decoder$10,
  fromJson: fromJson$10
};

var View = {};

function toString$1(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString$1
};

var Sort = {};

function toString$2(x) {
  var variant = x.NAME;
  if (variant === "Provider") {
    return "provider_name";
  } else if (variant === "State") {
    return "state";
  } else if (variant === "City") {
    return "city";
  } else {
    return "country";
  }
}

function toJson$1(sortBy) {
  return Json_Encode$JsonCombinators.object([
              [
                "sortBy",
                toString$2(sortBy)
              ],
              [
                "sortDirection",
                toString$1(sortBy.VAL)
              ]
            ]);
}

function toQueryString(sortBy) {
  return Qs.stringify(toJson$1(sortBy), {
              arrayFormat: "brackets"
            });
}

var SortBy = {
  toString: toString$2,
  toJson: toJson$1,
  toQueryString: toQueryString
};

var imageNotFoundUrl = Cloudinary.imageUrl("image-not-found.png");

function isApprovedToString(isApproved) {
  if (isApproved) {
    return "Approved";
  } else {
    return "Pending";
  }
}

function toBoolString(t) {
  if (t === "Approved") {
    return "true";
  } else {
    return "false";
  }
}

var ApprovalStatus = {
  toBoolString: toBoolString
};

function fromJs$12(js) {
  return {
          id: js.id,
          name: js.name
        };
}

var decoder$11 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$11(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$11));
}

var Filter = {
  fromJs: fromJs$12,
  decoder: decoder$11,
  fromJson: fromJson$11
};

var decoder$12 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              locations: field.required("locations", Json_Decode$JsonCombinators.array(decoder$11))
            };
    });

function fromJson$12(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$12));
}

var FilterList = {
  decoder: decoder$12,
  fromJson: fromJson$12
};

function fromJs$13(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug,
          salesManager: Belt_Option.map(Caml_option.nullable_to_opt(js.salesManager), salesManagerFromJs)
        };
}

var decoder$13 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", Json_Decode$JsonCombinators.$$int),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              salesManager: field.required("salesManager", Json_Decode$JsonCombinators.option(salesManagerDecoder))
            };
    });

function fromJson$13(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$13));
}

var LocationBrochure = {
  fromJs: fromJs$13,
  decoder: decoder$13,
  fromJson: fromJson$13
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  Show ,
  Dashboard ,
  Certification ,
  Facility ,
  Edit ,
  Active ,
  Detail ,
  ForMap ,
  ForTile ,
  Index ,
  RelatedLocations ,
  View ,
  SortDirection ,
  Sort ,
  SortBy ,
  imageNotFoundUrl ,
  isApprovedToString ,
  ApprovalStatus ,
  Filter ,
  FilterList ,
  LocationBrochure ,
}
/* decoder Not a pure module */
