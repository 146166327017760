// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toJson(hidden) {
  if (hidden === "Hidden") {
    return true;
  } else {
    return false;
  }
}

function toString(value) {
  if (value === "Hidden") {
    return "Hidden";
  } else {
    return "Shown";
  }
}

function fromJs(hidden) {
  if (hidden) {
    return "Hidden";
  } else {
    return "Shown";
  }
}

function fromBool(hidden) {
  if (hidden) {
    return "Hidden";
  } else {
    return "Shown";
  }
}

var Hidden = {
  toJson: toJson,
  toString: toString,
  fromJs: fromJs,
  fromBool: fromBool
};

var Field = {};

function fromJs$1(js) {
  return {
          id: js.id,
          hidden: js.hidden,
          sponsoredGlobal: js.sponsoredGlobal,
          sponsoredCountry: js.sponsoredCountry,
          sponsoredState: js.sponsoredState,
          sponsoredCity: js.sponsoredCity,
          locationId: js.locationId,
          colocationDirectory: js.colocationDirectory,
          cloudDirectory: js.cloudDirectory,
          internetDirectory: js.internetDirectory,
          bareMetalDirectory: js.bareMetalDirectory,
          networkDirectory: js.networkDirectory,
          colocationProjects: js.colocationProjects,
          bareMetalProjects: js.bareMetalProjects,
          overrideCity: js.overrideCity
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              hidden: field.required("hidden", Json_Decode$JsonCombinators.bool),
              sponsoredGlobal: field.required("sponsoredGlobal", Json_Decode$JsonCombinators.bool),
              sponsoredCountry: field.required("sponsoredCountry", Json_Decode$JsonCombinators.bool),
              sponsoredState: field.required("sponsoredState", Json_Decode$JsonCombinators.bool),
              sponsoredCity: field.required("sponsoredCity", Json_Decode$JsonCombinators.bool),
              locationId: field.required("locationId", ID.decoder),
              colocationDirectory: field.required("colocationDirectory", Json_Decode$JsonCombinators.bool),
              cloudDirectory: field.required("cloudDirectory", Json_Decode$JsonCombinators.bool),
              internetDirectory: field.required("internetDirectory", Json_Decode$JsonCombinators.bool),
              bareMetalDirectory: field.required("bareMetalDirectory", Json_Decode$JsonCombinators.bool),
              networkDirectory: field.required("networkDirectory", Json_Decode$JsonCombinators.bool),
              colocationProjects: field.required("colocationProjects", Json_Decode$JsonCombinators.bool),
              bareMetalProjects: field.required("bareMetalProjects", Json_Decode$JsonCombinators.bool),
              overrideCity: field.required("overrideCity", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  Hidden ,
  Field ,
  fromJs$1 as fromJs,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
